//======== Foundation ============

// Canvas

body {
  background-color: $color-background-shade;
}

// Basic text and typography

body {
  color: $color-copy;
}

//======== Material-UI elements ============

#page-content {

  // Text

  .MuiPaper-root {
    color: $color-copy; // Default text
  }

  .MuiTypography-colorTextSecondary {
    color: $color-body-copy; // Body text, paragraphs
  }

  .MuiTableCell-head {
    color: $color-body-copy; // Table headers
  }

  // Backgrounds

  .MuiPaper-rounded .MuiPaper-rounded {
    background-color: $color-content-shaded; // Nested tables (temporary solution)
    th {
      background-color: $color-content-shaded !important; // Nested tables (temporary solution)
    }
  }

  // Buttons

  .MuiFab-primary,
  .MuiButton-containedPrimary {
    color: #fff;
    background-color: $color-secondary;
      &.Mui-disabled {
        color: #fff;
        background-color: $color-secondary;
        opacity: .5 !important;
      }
  }

  .MuiButton-containedSecondary {
    color: $color-copy-contrast;
    background-color: $color-attention !important;
  }

  // Icons

  .MuiSvgIcon-root path {
    fill: $color-icon-default;
  }

  .MuiSvgIcon-colorSecondary path {
    fill: $color-copy-contrast;
  }

  .MuiIconButton-label {
    color: $color-icon-default;
  }

  .MuiFab-primary,
  .MuiButton-containedPrimary {
    .MuiSvgIcon-root path {
      fill: $color-icon-contrast;
    }
    .MuiIconButton-label {
      color: $color-icon-contrast;
    }
  }

  // Alerts and notifications

  .MuiAlert-filledError {
    color: $color-copy-contrast;
    background-color: $color-attention !important;
  }

  // Borders and lines

  .MuiTableCell-root {
    border-bottom: 1px solid $color-border-light;
  }

}

// Elevations and shadows

.MuiButton-contained {
  box-shadow: $shadow-size-m $shadow-color-default !important;
}

.MuiPaper-elevation1,
.MuiPaper-elevation2 {
  box-shadow: $shadow-size-m $shadow-color-default !important;

}

//======== Additional elements ============

// Key value component

.key-value-wrapper {
  border-color: $blueberry-skyr;

}

// Footer

.page-footer {
  .inner {
    span {
      color: $color-body-copy;
    }
  }
}

// Other

.label-group {
  background-color: '#333382';
  color: 'white';
}
